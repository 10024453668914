import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    // breakpoints: {
    //     values: {

    //     }
    // },
    typography: {
        fontFamily: "Varela Round, sans-serif",
    },
    palette: {
        primary: {
            main: '#84288D' // purple
        },
        secondary: {
            main: '#efe17f' // yellow
        },
    },
    overrides: {
        MuiLink: {
            root: {
                '&.buttonLink': {
                    background: '#efe17f',
                    fontWeight: '600',
                    padding: '1rem',
                }
            }
        },
        MuiTouchRipple : {
            root: {
                display: 'none'
            }
        },
        MuiGridList: {
            root: {
                justifyContent: 'space-around',
            }
        },
        MuiIconButton: {
            root: {
                '&.closeButton': {
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }   
            }
        }
    },
})