import React from 'react'
import styled from '@emotion/styled'
import { DEFAULT_PURPLE } from '../../constants'

const FooterWrapper = styled.footer`
    background-color: ${DEFAULT_PURPLE};
    height: 40px;
    padding: 10px 0px;
    position: absolute;
    bottom: 0;
    width: 100%;
`

const Copyright = styled.div`
    color: #FFF;
    text-align: center;
`

const Trademark = styled.div`
    color: #FFF;
    font-size: 12px;
    text-align: center;
`

export default function Footer () {

    return (
        <FooterWrapper id='footer'>
            <Copyright> Copyright {new Date().getFullYear()}, 4P's Dog Training</Copyright>
            <Trademark> Website Design &amp; Developed by Samantha Chan </Trademark>
        </FooterWrapper>
    )
}