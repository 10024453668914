import React, { useEffect, useState } from 'react'
import { withRouter, Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import { BP_TABLET, DEFAULT_PURPLE, DEFAULT_YELLOW } from './constants'
import SmoothScroll from 'smooth-scroll'

import About from './containers/About'
import Contact from './containers/Contact'
import Resources from './containers/Resources'
import Footer from './components/Footer'
import Header from './components/Header'
import Home from './containers/Home'
import NotFound from './containers/NotFound'
import MobileHeader from './components/MobileHeader'
import Services from './containers/Services'
import ScrollToTop from './common/ScrollToTop'

import styled from '@emotion/styled'
import Hidden from '@mui/material/Hidden'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

const AppWrapper = styled.div`
	width: 100%;
`
const AnnouncementBanner = styled.div`
	background: ${DEFAULT_PURPLE};
	color: #ffffff;
	padding: 0.5rem 0;
	text-align: center;
	width: 100%;
`
const HeaderWrapper = styled.div`
	display: block;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
`
const ModalBody = styled.div`
	background: #fbf9e5;
	border-radius: 5px;
	border: 5px ${DEFAULT_YELLOW} solid;
	line-height: 1.75rem;
	margin: auto;
	margin-top: 25%;
	outline: none;
	padding: 2rem 1rem;
	position: relative;
	width: 80%;

	@media (min-width: ${BP_TABLET}) {
		width: 45%;
	}
`
const CovidPolicyButton = styled(Button)`
	color: ${DEFAULT_YELLOW};
	font-size: 16px;
	fontweight: 500;
	text-transform: none;
	&:hover: {
		textdecoration: 'underline';
	}
`

function View(props) {
	const [shrinkHeader, setShrinkHeader] = useState(false)
	const [headerHeight, setHeaderHeight] = useState('')

	const resizeHeader = () => {
		const distanceY = window.pageYOffset
		distanceY > 0 ? setShrinkHeader(true) : setShrinkHeader(false)
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', resizeHeader)
			setHeaderHeight(document.getElementById('header').offsetHeight - 5 || 70)
		}

		new SmoothScroll('a[href*="#"]')

		const unlisten = props.history.listen(() => {
			window.scrollTo(0, 0)
		})
		return () => {
			window.removeEventListener('scroll', resizeHeader)
			unlisten()
		}
	}, [])

	const [open, setOpen] = useState(false)
	const handleOpenCovidPolicy = () => {
		setOpen(true)
	}

	const handleCloseCovidPolicy = () => {
		setOpen(false)
	}

	return (
		<Router>
			<AppWrapper>
				<HeaderWrapper>
					<Modal
						open={open}
						onClose={handleCloseCovidPolicy}
						aria-labelledby="dialog1Title"
						aria-describedby="dialog1Desc"
						aria-modal="true"
						role="dialog"
					>
						<ModalBody>
							<IconButton className="closeButton" onClick={handleCloseCovidPolicy} variant={'text'}>
								X
							</IconButton>
							<h2 id="dialogTitle">Covid-19 Policy</h2>
							<div id="dialog1Desc">
								I am currently offering training sessions outside (weather permitting) as well as in the
								home or in the garage. However, a mask must be worn by all family members and adhere to
								social distancing during sessions.
							</div>
						</ModalBody>
					</Modal>
					<AnnouncementBanner>
						<CovidPolicyButton href="" onClick={handleOpenCovidPolicy} className="bannerButton">
							Learn More
						</CovidPolicyButton>
						{`about our Covid-19 Policy`}
						{/* <BannerLink href="/services">* New Upcoming Fall Classes *</BannerLink>  */}
					</AnnouncementBanner>
					<Hidden mdUp implementation="css">
						<MobileHeader />
					</Hidden>
					<Hidden smDown implementation="css">
						<Header shrink={shrinkHeader} />
					</Hidden>
				</HeaderWrapper>
				<ScrollToTop>
					<Switch>
						<Route exact path="/" component={props => <Home headerHeight={headerHeight} />} />
						<Route path="/about" component={About} />
						<Route path="/services" component={Services} />
						<Route path="/resources" component={Resources} />
						<Route path="/contact" component={Contact} />
						<Route path="*" component={NotFound} />
					</Switch>
				</ScrollToTop>
				<Footer />
			</AppWrapper>
		</Router>
	)
}

export default withRouter(View)
