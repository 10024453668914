import React from 'react'
import styled from '@emotion/styled'
import { DEFAULT_WRAPPER } from '../../constants'

const PageWrapper = styled.div`
    ${DEFAULT_WRAPPER};
`
const MainContext = styled.h2`
    padding: 25vh 20px;
    margin: 0;
    max-width: 800px;
    font-size: 20px;
    text-align: center;
`

export default function NotFound() {
    return (
        <PageWrapper>
            <MainContext>I'm sorry, the page you are looking for does not exist.</MainContext>
        </PageWrapper>
    )
}