import React from 'react'
import styled from '@emotion/styled'
import { BP_TABLET, DEFAULT_PURPLE, DEFAULT_YELLOW } from '../../constants'

const ServiceCardWrapper = styled.div`
	background: ${DEFAULT_YELLOW};
	/* box-shadow: 2px 3px 10px 0 rgba(0,0,0,0.19); */
	display: block;
	color: ${DEFAULT_PURPLE};
	margin-bottom: 40px;
	position: relative;
	width: 100%;
	z-index: 1;

	@media (min-width: ${BP_TABLET}) {
		margin: 20px auto;
	}
`
const ServiceName = styled.h3`
	margin: 0;
	padding: 20px;
	padding-bottom: 0;
`
const ServiceDescription = styled.p`
	margin: 0;
	padding: 20px;
`
const OffsetElement = styled.div`
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	z-index: -1;
	top: -140px;
	left: 0;
	visibility: hidden;
`

export default function ServiceCard({ id, serviceDescription, serviceName }) {
	return (
		<ServiceCardWrapper>
			<OffsetElement id={id} />
			{serviceName && <ServiceName> {serviceName} </ServiceName>}
			<ServiceDescription dangerouslySetInnerHTML={{ __html: serviceDescription }} />
		</ServiceCardWrapper>
	)
}
