import Background from './images/background.jpeg'

export const DEFAULT_PURPLE = '#84288D'
export const DEFAULT_YELLOW = '#efe17f'

export const BP_TABLET = '768px'
export const BP_MOBILE = '480px'

export const HEADER1 = `
    font-size: 38px;
`
export const HEADER2 = `
    font-size: 28px;
`

export const BACKGROUND = `
    background: url(${Background});
    background-size: 300px 300px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom right;
    height: 100%;
    opacity: .05;
    position: fixed;
    width: 100%;
    z-index: -1000;

    @media (min-width: ${BP_TABLET}) {
        background-size: 500px 500px;
    }
`

export const CONTEXT = `
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 30px;
`

export const DEFAULT_WRAPPER = `
    width: 100%;
    margin-bottom: 80px;
`
