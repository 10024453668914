import Image1 from '../../images/dog_in_air.jpeg'
import Image2 from '../../images/dog_sitting.jpeg'
import Contact1 from '../../images/contact_1.jpg'
import Contact2 from '../../images/contact_2.jpg'
import Contact3 from '../../images/contact_3.jpg'
import Contact4 from '../../images/contact_4.jpg'
import Contact5 from '../../images/contact_5.jpg'
import Contact6 from '../../images/contact_6.jpg'
import Contact7 from '../../images/contact_7.JPG'
import Contact8 from '../../images/contact_8.JPG'
import Contact9 from '../../images/contact_9.JPG'
import Contact10 from '../../images/contact_10.JPG'
import Contact11 from '../../images/contact_11.JPG'
import Contact12 from '../../images/contact_12.JPG'
import Contact13 from '../../images/contact_13.JPG'
import Contact14 from '../../images/contact_14.JPG'
import Contact15 from '../../images/contact_15.JPG'
import Contact16 from '../../images/contact_16.JPG'
import Contact17 from '../../images/contact_17.JPG'
import Contact18 from '../../images/contact_18.JPG'
import Contact19 from '../../images/contact_19.JPG'
import Contact20 from '../../images/contact_20.JPG'
import Contact21 from '../../images/contact_21.JPG'
import Contact22 from '../../images/contact_22.JPG'
import Contact23 from '../../images/contact_23.JPG'
import Contact24 from '../../images/contact_24.JPG'
import Contact25 from '../../images/contact_25.JPG'
import Contact26 from '../../images/contact_26.JPG'
import Contact27 from '../../images/contact_27.JPG'
import Contact28 from '../../images/contact_28.JPG'
import Contact29 from '../../images/contact_29.JPG'
import Contact30 from '../../images/contact_30.JPG'
import Contact31 from '../../images/contact_31.JPG'
import Contact32 from '../../images/contact_32.JPG'
import Contact33 from '../../images/contact_33.JPG'
import Contact34 from '../../images/contact_34.JPG'
import Contact35 from '../../images/contact_35.JPG'
import Contact36 from '../../images/contact_36.JPG'
import Contact37 from '../../images/contact_37.JPG'
import Contact38 from '../../images/contact_38.JPG'
import Contact39 from '../../images/contact_39.JPG'
import Contact40 from '../../images/contact_40.JPG'
import Contact41 from '../../images/contact_41.JPG'
import Contact42 from '../../images/contact_42.JPG'
import Contact43 from '../../images/contact_43.JPG'	
import Contact44 from '../../images/contact_44.JPG'
import Contact45 from '../../images/contact_45.JPG'


export const IMAGE_LIST = [
	{ src: Image1 },
	{ src: Image2 },
	{ src: Contact1 },
	{ src: Contact2 },
	{ src: Contact3 },
	{ src: Contact4 },
	{ src: Contact5 },
	{ src: Contact6 },
	{ src: Contact7 },
	{ src: Contact8 },
	{ src: Contact9 },
	{ src: Contact10 },
	{ src: Contact11 },
	{ src: Contact12 },
	{ src: Contact13 },
	{ src: Contact14 },
	{ src: Contact15 },
	{ src: Contact16 },
	{ src: Contact17 },
	{ src: Contact18 },
	{ src: Contact19 },
	{ src: Contact20 },
	{ src: Contact21 },
	{ src: Contact22 },
	{ src: Contact23 },
	{ src: Contact24 },
	{ src: Contact25 },
	{ src: Contact26 },
	{ src: Contact27 },
	{ src: Contact28 },
	{ src: Contact29 },
	{ src: Contact30 },
	{ src: Contact31 },
	{ src: Contact32 },
	{ src: Contact33 },
	{ src: Contact34 },
	{ src: Contact35 },
	{ src: Contact36 },
	{ src: Contact37 },
	{ src: Contact38 },
	{ src: Contact39 },
	{ src: Contact40 },
	{ src: Contact41 },
	{ src: Contact42 },
	{ src: Contact43 },
	{ src: Contact44 },
	{ src: Contact45 },
]