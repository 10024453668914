import React from 'react'
import styled from '@emotion/styled'
import { BACKGROUND, BP_TABLET, DEFAULT_WRAPPER } from '../../constants'

import Link from '@mui/material/Link'

const DefaultWrapper = styled.section`
	${DEFAULT_WRAPPER};
`
const BackgroundWrapper = styled.div`
	${BACKGROUND};
`
const ResourcesWrapper = styled.div`
	font-size: 20px;
	margin: 0 auto;
	max-width: 800px;
	padding-top: 100px;
	width: 90%;

	@media (min-width: ${BP_TABLET}) {
		padding: 150px 30px 0 30px;
		margin: 50px auto;
		max-width: 800px;
	}
`
const ResourcesHeader = styled.h2``
const RecommendedContent = styled.ul`
	font-size: 20px;

	& a {
		text-decoration: none;
	}
`
const Recommendation = styled.li``
export default function Resources() {
	return (
		<DefaultWrapper>
			<BackgroundWrapper />
			<ResourcesWrapper>
				<ResourcesHeader>Recommended Reading:</ResourcesHeader>
				<RecommendedContent>
					<Recommendation>Dr Ian Dunbar, Before and After Getting Your Puppy</Recommendation>
					<Recommendation>Karen Pryor, Clicker Training for Dogs</Recommendation>
					<Recommendation>Patricia A McConnell, The Other End of The Leash</Recommendation>
				</RecommendedContent>
				<ResourcesHeader>Online Resources:</ResourcesHeader>
				<RecommendedContent>
					<Recommendation>Karen Pryor Clicker Training</Recommendation>
					<Link href="https://www.youtube.com/channel/UCr9Z_274MqNWbHNcgBFiE2g">YouTube</Link>
					<Recommendation>The Association of Professional Dog Trainers </Recommendation>
					<Link href="http://www.apdt.com">http://www.apdt.com</Link>
				</RecommendedContent>
				{/* <ResourcesHeader>Pet Photography:</ResourcesHeader> */}
			</ResourcesWrapper>
		</DefaultWrapper>
	)
}
