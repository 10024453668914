import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { BACKGROUND, BP_MOBILE, BP_TABLET, CONTEXT, DEFAULT_WRAPPER } from '../../constants'
import LazyLoad from 'react-lazy-load'
import AboutMeImg from '../../images/about.jpeg'
import MembershipLogo from '../../images/association-of-professional-dog-trainers.jpeg'
import AnimalBehaviorCollege from '../../images/animal-behavior-college.jpeg'
import CCPDTCertification from '../../images/cpdt-certification.png'
import Link from '@mui/material/Link'

const DefaultWrapper = styled.section`
	${DEFAULT_WRAPPER}
`
const BackgroundWrapper = styled.div`
	${BACKGROUND};
`
const AboutWrapper = styled.div`
	margin: 0 auto;
	padding-top: 100px;
	width: 90%;

	@media (min-width: ${BP_TABLET}) {
		padding: 150px 30px 0 30px;
		margin: 50px auto;
		max-width: 800px;
	}
`
const AboutTitle = styled.h2`
	display: block;
	margin: auto;
	margin-bottom: 20px;
	text-align: center;

	@media (min-width: ${BP_TABLET}) {
		margin: 20px 0;
		text-align: left;
	}
`
const BioWrapper = styled.div`
	${CONTEXT}
	margin: auto;
	padding-bottom: 30px;

	@media (min-width: ${BP_TABLET}) {
		flex-direction: row;
	}
`
const BioImage = styled.img`
	background-image: url(${AboutMeImg});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	margin: auto;
	margin-bottom: 30px;
	height: 300px;
	width: 95%;

	@media (min-width: ${BP_TABLET}) {
		display: inline-block;
		float: left;
		margin: 0 40px 40px 0;
		width: 300px;
	}
`
const Divider = styled.div`
	border-bottom: 1px solid rgba(225, 225, 225);
`
const InfoSection = styled.div`
	display: block;
	margin: 30px auto;
	padding-bottom: 20px;
	text-align: center;
	width: 100%;

	@media (min-width: ${BP_MOBILE}) {
		margin: 0;
		text-align: left;
	}

	@media (min-width: ${BP_MOBILE}) and (max-width: ${BP_TABLET}) {
		margin: auto;
	}
`
const Certification = styled.div`
	display: flex;
	flex-wrap: wrap;
	text-align: center;

	@media (min-width: ${BP_MOBILE}) {
		text-align: left;
	}
`
const InfoTitle = styled.h2`
	text-align: center;

	@media (min-width: ${BP_MOBILE}) {
		text-align: left;
	}
`
const MembershipImgLoader = styled(LazyLoad)`
	height: auto;
	margin: 1rem auto;
	text-align: center;
	width: 100%;

	@media (min-width: ${BP_MOBILE}) {
		margin: auto 1rem;
		width: auto;
	}
`
const MembershipImg = styled.img`
	height: inherit;
	width: inherit;
`

function About() {
	return (
		<DefaultWrapper>
			<BackgroundWrapper />
			<AboutWrapper>
				<AboutTitle> About Dianne Lotti </AboutTitle>
				<BioWrapper>
					<MembershipImgLoader>
						<BioImage />
					</MembershipImgLoader>
					{/* <Bio> */}
					My love of dogs and all animals started at a very young age. I was the neighborhood animal
					caregiver. Anytime a wild animal was abandoned or injured it was brought to me where I nursed it
					back to health. This is when I knew I wanted to work with animals.
					<br />
					<br />
					As soon as I was old enough I got a job working for a local Veterinarian. What a great experience,
					so much so I went to college got a degree in Animal Science and continued to work as a Veterinarian
					Technician for another 12 years.
					<br />
					<br />
					While I do have a corporate career, I am back doing what I truly love which is working with the dogs
					as a certified dog trainer. I am a graduate of the Animal Behavior College (ABC) dog training
					program specializing in puppy training and beginner obedience. I also received the CPDT-KA
					certification as well as became an AKC Evaluator, #102403
					<br />
					<br />I look forward to assisting you with reaching your goals for a well-mannered family companion.
					{/* </Bio> */}
				</BioWrapper>
				<Divider />
				<InfoSection>
					<InfoTitle>Certifications:</InfoTitle>
					<Certification>
						<MembershipImgLoader offset={100}>
							<Link href="http://www.animalbehaviorcollege.com">
								<img alt="ABC Certified Trainer" src={AnimalBehaviorCollege} />
								<br />
								Certified Dog Trainer
							</Link>
						</MembershipImgLoader>
						<MembershipImgLoader>
							<img alt="CPTD" src={CCPDTCertification} />
						</MembershipImgLoader>
					</Certification>
				</InfoSection>
				<Divider />
				<InfoSection>
					<InfoTitle>Memberships:</InfoTitle>
					<MembershipImgLoader offset={100} width={200}>
						<MembershipImg alt="Association of Professional Dog Trainers" src={MembershipLogo} />
					</MembershipImgLoader>
				</InfoSection>
			</AboutWrapper>
		</DefaultWrapper>
	)
}

export default withRouter(About)
