import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { NavLink, withRouter } from 'react-router-dom'
import { DEFAULT_PURPLE, DEFAULT_YELLOW } from '../../constants.jsx'

import LogoImage from '../../images/4Ps_Logo.png'

const HeaderWrapper = styled.div`
	position: relative;
	width: 100%;
	z-index: 1000;
`
const NavWrapper = styled.div`
	background-color: #fff;
	box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.19);
	display: flex;
	justify-content: space-between;
	height: 60px;
	padding: 5px 0;
	position: absolute;
	width: 100%;
	z-index: 1;
`
const Logo = styled.img`
	border-radius: 50%;
	display: inline-block;
	height: 60px;
	margin-left: 10px;
	transition: all 0.5s ease-out;
	width: 60px;
`
const HeaderTitle = styled.h1`
	color: ${DEFAULT_PURPLE};
	display: inline-block;
	font-size: 26px;
	margin: auto;
	text-align: center;
`
const BurgerMenu = styled.div`
	display: inline-block;
	margin: 10px;
	width: auto;
`
const BurgerLayer = styled.div`
	background-color: ${DEFAULT_PURPLE};
	height: 5px;
	margin: 6px 0;
	width: 35px;
`
const NavAlign = styled.div`
	margin: 0 auto;
	padding-top: 70px;
`
const NavElements = styled.ul`
	background-color: ${DEFAULT_PURPLE};
	float: right;
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	width: 125px;
`
const NavElement = styled.li`
	font-size: 20px;

	& a {
		color: #fff;
		display: block;
		padding: 10px;
		text-align: center;
		text-decoration: none;
	}

	& :hover {
		background-color: lavender;
		color: #673399;
	}
`

function MobileHeader ({ history, shrink = false }) {
	const [showMenu, setShowMenu] = useState(false)
	const mobileRef = useRef()

	useEffect(() => {
		document.addEventListener('mousedown', handleClicks, false)
		return () => {
			document.addEventListener('mousedown', handleClicks, false)
		}
	}, [])

	const handleBurgerMenuClick = () => {
		setShowMenu(!showMenu)
	}

	const handleClicks = e => {
		if (mobileRef === e.target) {
			setShowMenu(false)
		}
	}

	const handleLogoClick = () => {
		history.push('/')
	}

	const handleNavClick = () => {
		setShowMenu(false)
	}

	const activeStyle = { background: DEFAULT_YELLOW }

	return (
		<HeaderWrapper ref={mobileRef}>
			<NavWrapper id="mobile-header">
				<Logo alt="4Ps Training" shrink={shrink} onClick={handleLogoClick} src={LogoImage} />
				<HeaderTitle onClick={handleLogoClick}>4 P's Dog Training</HeaderTitle>
				<BurgerMenu onClick={handleBurgerMenuClick}>
					<BurgerLayer />
					<BurgerLayer />
					<BurgerLayer />
				</BurgerMenu>
				{/* Navigation Burger */}
			</NavWrapper>
			{showMenu && (
				<NavAlign>
					<NavElements>
						<NavElement>
							<NavLink activeStyle={activeStyle} exact to="/" onClick={handleNavClick}>
								{' '}
								Home{' '}
							</NavLink>
						</NavElement>
						<NavElement>
							<NavLink activeStyle={activeStyle} to="/About" onClick={handleNavClick}>
								{' '}
								About Me{' '}
							</NavLink>
						</NavElement>
						<NavElement>
							<NavLink activeStyle={activeStyle} to="/Services" onClick={handleNavClick}>
								{' '}
								Services{' '}
							</NavLink>
						</NavElement>
						<NavElement>
							<NavLink activeStyle={activeStyle} to="/Resources" onClick={handleNavClick}>
								{' '}
								Resources{' '}
							</NavLink>
						</NavElement>
						<NavElement>
							<NavLink activeStyle={activeStyle} to="/Contact" onClick={handleNavClick}>
								{' '}
								Contact{' '}
							</NavLink>
						</NavElement>
					</NavElements>
				</NavAlign>
			)}
		</HeaderWrapper>
	)
}

export default withRouter(MobileHeader)
