import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'
import { BACKGROUND, BP_TABLET, CONTEXT, DEFAULT_WRAPPER, DEFAULT_PURPLE, DEFAULT_YELLOW } from '../../constants'

import ServiceCard from '../../components/ServiceCard'
import Link from '@mui/material/Link'

const DefaultWrapper = styled.section`
	${DEFAULT_WRAPPER}
	margin-bottom: 100px;
`
const ServicesWrapper = styled.div`
	${CONTEXT}
	max-width: 800px;
	margin: 50px auto 0;
	padding-top: 100px;
	width: 85%;

	@media (min-width: ${BP_TABLET}) {
		padding: 150px 30px 0 30px;
		margin: 125px auto;
		max-width: 800px;
	}
`
const BackgroundWrapper = styled.div`
	${BACKGROUND};
`
const ServiceHeader = styled.h2`
	margin-bottom: 0;
`
const ServiceContent = styled.p`
	${CONTEXT}
`
const ServiceSection = styled.div`
	display: block;

	@media (min-width: 600px) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
`
const Type = styled.div`
	font-size: 24px;
	margin: 1.5em 0 1em 0;
`
const Disclosure = styled.div`
	font-size: 18px;
	margin-top: 0.5em;
	margin-bottom: 1em;

	@media (min-width: ${BP_TABLET}) {
		font-size: 20px;
	}
`
const TableWrapper = styled.div`
	margin-top: 2rem;
	overflow-y: hidden;
	width: 100%;

	::-webkit-scrollbar {
		-webkit-overflow-scrolling: auto;
		-webkit-appearance: none;
		background: #f3f3f3;
		border-radius: 10px;
		height: 8px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: gray;
	}
`
const ServiceTable = styled.table`
	border: none;
	border-collapse: collapse;
	font-size: 16px;
	display: table;
	width: 100%;

	tr,
	th {
		padding: 10px 5px;
	}
	.minWidth {
		min-width: 150px;
	}
	a {
		color: ${DEFAULT_PURPLE};
	}

	tr:nth-of-type(even) {
		background: ${DEFAULT_YELLOW};
	}
	tr:nth-of-type(odd) {
		background: rgba(132, 40, 141, 0.3);
	}
	tr:nth-of-type(1) {
		color: white;
		background: ${DEFAULT_PURPLE};
	} 
/* 
	@media (min-width: ${BP_TABLET}) {
		th {
			min-width: auto;
		}
	} */
`
const Divider = styled.div`
	border-bottom: 1px solid rgba(225, 225, 225);
	margin-bottom: 30px;
`
const table = [
	{
		id: 'puppy-obedience',
		day: 'Saturday',
		time: '8:30am',
		course: 'Puppy Obedience',
		prerequisite: 'None',
		age: '< 6 months',
		length: '6 Weeks',
		price: '$225',
	},
	{
		id: 'beginner-obedience',
		day: 'Saturday',
		time: '9:45am',
		course: 'Beginner Obedience',
		prerequisite: 'None',
		age: '≥ 6 months',
		length: '6 Weeks',
		price: '$225',
	},
	{
		id: 'build-upon-the-basics',
		day: 'Saturday',
		time: '11:00am',
		course: 'Build upon the Basics',
		prerequisite: 'Either Puppy or Beginner Obedience',
		age: '≥ 6 months',
		length: '5 Weeks',
		price: '$225',
	},
	// {
	// 	id: 'advanced-obedience',
	// 	day: 'Saturday',
	// 	time: '11:45am',
	// 	course: 'Advanced obedience / CGC',
	// 	prerequisite: 'Intermediate Obedience',
	// 	age: '≥ 6 months',
	// 	length: '6 Weeks',
	// 	price: '$225',
	// },
	// {
	// 	id: 'puppy-obedience',
	// 	day: 'Wednesday',
	// 	time: '6:30pm',
	// 	course: 'Puppy Obedience',
	// 	prerequisite: 'None',
	// 	age: '< 6 months',
	// 	length: '6 Weeks',
	// 	price: '$225',
	// },
	{
		id: 'intro-to-akc-rally',
		day: 'Thursday',
		time: '6:30pm',
		course: 'Intro to AKC Rally',
		prerequisite: 'Esither Puppy Obedience or Beginner Obedience are required pre-requisites',
		age: '≥ 6 months',
		length: '5 Weeks',
		price: '$185',
	},
]

function Services() {
	return (
		<DefaultWrapper>
			<BackgroundWrapper />
			<ServicesWrapper>
				<ServiceHeader>Private Training Sessions - in your home or virtual:</ServiceHeader>
				<ServiceCard
					serviceDescription="One on one training customized to address your specific needs. I can help with:
					<ul>
						<li>Manners</li>
						<li>Basic obedience</li>
						<li>Puppy training and socialization</li>
						<li>Coorperative care and husbandry</li>
						<li>Leash walking</li>
						<li>Coming when called</li>
						<li>Barking and lunging on leash</li>
					</ul>
					Each session, I’ll provide you handouts of the training we reviewed."
				/>
				<div>
					Contact me via email, <Link href="mailto:4PsDogTraining@gmail.com">4PsDogTraining@gmail.com</Link>,
					to book your private training
				</div>
				<br />
				<div>Included in your fee is unlimited contact between sessions.</div>
				<br />
				{/* <Divider />
				<ServiceSection>
					<ServiceHeader id="group-class-schedule">Group Classes:</ServiceHeader>
					<Disclosure>
						Held at <Link href="https://offtheleashddc.com/">Off the Leash Doggie Daycare</Link>, Attleboro
						MA
					</Disclosure>
					<div style={{ display: 'flex', marginBottom: '28px' }}>
						<Link
							href="https://offtheleashddc.com/training-class-schedule/"
							className="buttonLink"
							rel="noreferrer noopener"
							target="_blank"
						>
							Group Class Registration
						</Link>
					</div>
					Each session, I’ll provide you handouts of the training we reviewed.
					<ServiceCard
						id="puppy-obedience"
						serviceDescription="This is a 6-week course for puppies ages 8-20 weeks of age. It is comprised of basic training in
						puppy manners, puppy socialization and behavior management. You will learn how to teach
						your puppy to come when called, walk nicely on leash, sit, lie down, stay, greet people politely,
						and more. Puppies will have the opportunity to safely interact with one another to help build their
						skills in socializing with other dogs in an appropriate manner. Home management issues such
						as biting, barking, house training, and jumping will be discussed."
						serviceName="Puppy Obedience"
					/>
					<ServiceCard
						id="beginner-obedience"
						serviceDescription="This is a 6-week course for <b><u>dogs over 5 months</u></b> of age who have had no previous formal
						training. The curriculum is similar to Puppy Obedience, encompassing coming when called,
						walk nicely on leash, sit, lie down, stay, greet people politely, etc., but geared towards the older
						dog. Emphasis will be on the application of training to daily life and on strengthening your
						relationship with your dog."
						serviceName="Beginner Obedience"
					/>
					<ServiceCard
						id="build-upon-the-basics"
						serviceDescription="Now that you have completed Puppy or Beginner obedience training what do you do
						next?
						<br />
						Let’s build upon those basic skills. This 5-week course builds on previously taught
						behaviors and begins more advanced behaviors. We will work on increasing the
						difficulty, distance, distractions, and duration of the basic obedience skills to ensure the
						behavior is consistent no matter the location or distraction."
						serviceName="Build Upon the Basics"
					/>
					<ServiceCard
						id="intro-to-akc-rally"
						serviceDescription="A fun way to continue building a bond with your dog. Think of an AKC Rally event as
						any team sport: You and your dog navigate a course, side-by-side, as you steer him
						through a course of 10-15 different signs. Each of these signs provides instructions
						regarding the next skill that is to be performed. The dog and handler move continuously
						throughout the course with the dog under control at the handler’s left side. There is a
						clear sense of teamwork between the dog and handler both during and between the
						numbered signs. Rally is all about working as a team while performing the skills, with
						the dog under control."
						serviceName="Intro to AKC Rally"
					/>
					COMMENTED OUT CARD
					<ServiceCard
						id="intermediate-obedience"
						serviceDescription="Building on previously taught behaviors, you will continue to learn and incorporate learning theory to better understand how to ensure that behavior is consistent no matter the location or distraction, will start off leash training."
						serviceName="#3. Intermediate Obedience"
					/>
					COMMENTED OUT CARD
					<ServiceCard
						id="advanced-obedience"
						serviceDescription="Is for any dog that has completed a previous training class. This class will focus on adding distractions, distance, hand-signals, advance greetings and off leash commands. It will also incorporate the Canine Good Citizenship requirements if the owners want to pursue the CGC Certification. This will include one hour initial consultation plus 5 one hour sessions."
						serviceName="#4. Advanced Obedience / CGC"
					/>
				</ServiceSection>
				<Divider />
				<TableWrapper>
					<ServiceTable>
						<table width="100%">
							<tr>
								<th>Day:</th>
								<th className="minWidth">Course:</th>
								<th>Prerequisite:</th>
								<th className="minWidth">Age:</th>
								<th>Length:</th>
							</tr>
							{table.map((row, i) => {
								const { day, time, course, id, prerequisite, age, length, price } = row
								return (
									<tr key={i}>
										<th>{`${day} ${time}`}</th>
										<th className="minWidth">
											{' '}
											<a href={`#${id}`}>{course}</a>
										</th>
										<th>{prerequisite}</th>
										<th className="minWidth">{age}</th>
										<th>{length}</th>
									</tr>
								)
							})}
						</table>
					</ServiceTable>
				</TableWrapper> */}
			</ServicesWrapper>
		</DefaultWrapper>
	)
}

export default withRouter(Services)
