import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { DEFAULT_PURPLE, DEFAULT_YELLOW } from '../../constants'
import styled from '@emotion/styled'

import LogoImage from '../../images/4Ps_Logo.png'

const HeaderWrapper = styled.div`
	background: #fff;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	/* box-shadow: 1px 1px 10px #888888; */
	height: auto;
	position: relative;
	top: 0;
	width: 100%;
	z-index: 1000;
`
const Logo = styled.img`
    cursor: pointer;
    height: ${props => toggleSize(props.shrink)};
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    transition: all .5s ease-out;
    /* margin-left: ${props => props.shrink && '100px'}; */
`
const NavWrapper = styled.div`
	margin: auto;
	position: relative;
`
const NavElements = styled.ul`
	bottom: 0;
	display: flex;
	list-style-type: none;
	margin: auto;
	max-width: 650px;
	padding: 13px 13px 13px 0;
	position: absolute;
	right: 0;
	width: 70%;
`
const NavElement = styled.li`
	float: left;
	font-size: 18px;
	width: 100%;

	& a {
		color: ${DEFAULT_PURPLE};
		display: block;
		padding: 16px;
		text-align: center;
		text-decoration: none;
	}

	& :hover {
		background-color: ${DEFAULT_YELLOW};
		color: #673399;
	}
`

const toggleSize = shrink => {
	return shrink ? '75px' : '150px'
}

function Header ({history, shrink = false}) {
	const handleLogoClick = () => {
		history.push('/')
	}
	const activeTab = { background: DEFAULT_YELLOW, fontWeight: '600' }

	return (
		<HeaderWrapper>
			<NavWrapper id="header">
				<Logo
					align={shrink ? '13px 0' : 'auto'}
					alt="4Ps Training"
					onClick={handleLogoClick}
					src={LogoImage}
					shrink={shrink}
					translate={shrink ? '70px' : '0px'}
				/>
				<NavElements>
					<NavElement>
						<NavLink activeStyle={activeTab} exact to="/">
							Home
						</NavLink>
					</NavElement>
					<NavElement>
						<NavLink activeStyle={activeTab} to="/about">
							About Me
						</NavLink>
					</NavElement>
					<NavElement>
						<NavLink activeStyle={activeTab} to="/services">
							Services
						</NavLink>
					</NavElement>
					<NavElement>
						<NavLink activeStyle={activeTab} to="/resources">
							Resources
						</NavLink>
					</NavElement>
					<NavElement>
						<NavLink activeStyle={activeTab} to="/contact">
							Contact
						</NavLink>
					</NavElement>
				</NavElements>
			</NavWrapper>
		</HeaderWrapper>
	)
}

export default withRouter(Header)
