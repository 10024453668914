import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'

import { BP_MOBILE, BP_TABLET, CONTEXT, DEFAULT_PURPLE, DEFAULT_YELLOW } from '../../constants'

import HomeBanner from '../../images/homepage_white_opacity.jpeg'
import Button from '@mui/material/Button'

const HomeWrapper = styled.div`
	width: 100%;
	margin-bottom: 50px;
`
const CovidButton = styled.div`
	width: 100%;
	position: absolute;
	bottom: 10%;
	margin-top: auto;
	margin: auto;
	text-align: center;
`
const TopBanner = styled.div`
	background-image: url(${HomeBanner});
	background-size: 120%;
	background-position: center 70px;
	background-repeat: no-repeat;
	background-attachment: fixed;
	min-height: 300px;
	height: 45vh;
	position: relative;

	@media (min-width: ${BP_MOBILE}) {
		min-height: 450px;
	}

	@media (min-width: 560px) {
		background-position: center 0;
		/* min-height: 575px; */
	}

	@media (min-width: ${BP_TABLET}) {
		background-position: top left;
	}

	@media (min-width: 890px) {
		background-position: center;
		background-size: 100%;
	}

	@media (min-width: 1000px) {
		background-position: center 20px;
		height: 75vh;
	}
`
const WhyChooseUs = styled.section`
	background: linear-gradient(50deg, rgba(239, 225, 127, 0.08) 10%, #fff, rgba(239, 225, 127, 8%));
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
	padding-bottom: 30px;
	position: relative;
`
// Sections
const SectionBackground = styled.div`
	background: ${({ background }) => background || DEFAULT_YELLOW};
`
const List = styled.section`
	background: ${DEFAULT_YELLOW};
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	height: 100%;
	max-width: 800px;
	margin: auto;
`
const ListHeader = styled.div`
	display: block;
	margin: auto;
	background: ${DEFAULT_YELLOW};
	text-align: center;
	max-width: 800px;
	width: 100%;

	@media (min-width: ${BP_TABLET}) {
		background: none;
		position: absolute;
	}
`
const ListTitle = styled.h2`
	background-color: ${DEFAULT_PURPLE};
	color: #ffffff;
	display: inline-block;
	margin-bottom: 0;
	min-width: 330px;
	padding: 5px 10px;
	text-align: center;
	width: auto;
`
const ListCards = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 100%;

	@media (min-width: ${BP_MOBILE}) {
		justify-content: space-between;
	}

	@media (min-width: ${BP_TABLET}) {
		flex-direction: row;
	}
`
const ListCard = styled.div`
	${CONTEXT}
	display: block;
	background: ${props => (props.background ? props.background : DEFAULT_YELLOW)};
	color: ${DEFAULT_PURPLE};
	line-height: 40px;
	padding: 20px 0;
	text-align: center;
	width: 100%;

	@media (min-width: ${BP_TABLET}) {
		padding: 4.5rem 2rem 3rem 2rem;
		width: 33.33%;
	}
`
const Context = styled.p`
	${CONTEXT}
	margin: 0 20px;

	@media (min-width: ${BP_MOBILE}) {
		font-size: 20px;
		margin: 0 50px;
		max-width: 800px;
	}
	@media (min-width: ${BP_TABLET}) {
		margin: auto;
		padding: 0 30px 30px 30px;
	}
`
const HeaderButton = styled(Button)`
	color: ${DEFAULT_PURPLE};
	padding: 16px;
`
const SectionTitle = styled.h2`
	text-align: center;
	margin: 0;
	padding: 20px 0;
`
const obediences = [
	['Name Game', 'Leave it', 'Wait', 'Greetings', 'Potty training'],
	['Sit', 'Drop', "Let's Go", 'Come', 'Crate Training'],
	['Down', 'Stay', 'Place', 'Loose Leash Walking'],
]
const behaviors = [
	['Pulling on the leash', 'Not coming when called', 'House training'],
	['Running out the door', 'Chewing, nipping', 'Excessive Barking'],
	['Jumping on people', 'Counter surfing', 'High Energy'],
]

function Home() {
	return (
		<HomeWrapper>
			<TopBanner>
				<CovidButton>
					<HeaderButton
						onClick={() => (window.location.href = '/services')}
						color="secondary"
						variant="contained"
					>
						Available Services
					</HeaderButton>
				</CovidButton>
			</TopBanner>
			<WhyChooseUs>
				<SectionTitle>Why Choose Us?</SectionTitle>
				<Context>
					4 P’s Dog Training recognizes each owner and each dog learns in different ways. I will create an
					environment for success by using behavioral science backed, humane, and reward based training
					methods. The training will be conducted in your home to ensure the training really fits your
					lifestyle and your dog’s personality. I encourage the whole family to get involved. I will work with
					and educate you so you gain the knowledge and confidence to create that special bond with your dog.
				</Context>
			</WhyChooseUs>

			<SectionBackground background="#f7ea94">
				<List>
					<ListHeader>
						<ListTitle> Obedience Training: </ListTitle>
					</ListHeader>
					<ListCards>
						{obediences.map((obedienceList, i) => {
							return (
								<ListCard background={i % 2 === 0 && '#f7ea94'} key={i}>
									{obedienceList.map(obedience => {
										return (
											<>
												{obedience}
												<br />
											</>
										)
									})}
								</ListCard>
							)
						})}
					</ListCards>
				</List>
			</SectionBackground>
			<SectionBackground>
				<List>
					<ListHeader>
						<ListTitle> Unwanted Behaviors Solved: </ListTitle>
					</ListHeader>
					<ListCards>
						{behaviors.map((List, i) => {
							return (
								<ListCard background={i % 2 !== 0 && '#f7ea94'}>
									{List.map(behavior => {
										return (
											<>
												{behavior}
												<br />
											</>
										)
									})}
								</ListCard>
							)
						})}
					</ListCards>
				</List>
			</SectionBackground>
		</HomeWrapper>
	)
}

export default withRouter(Home)
